import MuiTooltip, {type TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip';
import type * as React from 'react';

const styles = {
  tooltip: {
    p: 1.2,
    whiteSpace: 'pre-line',
    backgroundColor: 'background.paper',
    color: 'text.primary',
    boxShadow: 8,
    lineHeight: '16px',
    fontWeight: 'medium',
  },
  arrow: {
    color: 'background.paper',
  },
};

interface TooltipProps {
  title?: React.ReactNode;
  followCursor?: boolean;
  placement?: MuiTooltipProps['placement'];
  children: React.ReactElement;
}

function Tooltip({followCursor, title, placement = 'top', children}: TooltipProps) {
  if (title) {
    return (
      <MuiTooltip
        arrow
        title={title}
        followCursor={followCursor}
        placement={placement}
        componentsProps={{
          tooltip: {sx: styles.tooltip},
          arrow: {sx: styles.arrow},
        }}
      >
        {/* span is needed for disabling buttons with tooltips, see https://mui.com/material-ui/react-tooltip/#disabled-elements */}
        {/* span can also hold a ref to which the tooltip will need to anchor itself,
          whereas a child component passed in may not, see https://mui.com/material-ui/guides/composition/#caveat-with-refs */}
        <span>{children}</span>
      </MuiTooltip>
    );
  }
  return children;
}

export default Tooltip;
