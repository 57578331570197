export const AccentColors = {
  RED: '#ff0054',
  BLUE: '#1e96fc',
  PURPLE: '#7209b7',
  AMBER: '#ffa200',
  GREEN: '#06d6a0',
};
export const AccentColorsArray = Object.values(AccentColors);

export const PaymentPlanColors = {
  free: AccentColors.BLUE,
  standard: AccentColors.AMBER,
  enterprise: AccentColors.GREEN,
  enterprise_trial: AccentColors.GREEN,
  dedicated: AccentColors.PURPLE,
  internal: AccentColors.RED,
};

export const SupportTierColors = {
  free: AccentColors.BLUE,
  developer: AccentColors.AMBER,
  pro: AccentColors.PURPLE,
  enterprise: AccentColors.GREEN,
  enterprise_trial: AccentColors.GREEN,
  internal: AccentColors.RED,
};

export const UserRoleColors = {
  owner: AccentColors.BLUE,
  billingAdmin: AccentColors.AMBER,
  user: AccentColors.PURPLE,
  default: AccentColors.BLUE,
};

export const PaymentStatusColors = {
  active: '#06d6a0',
  payment_pending: '#ebf200',
  past_due: '#ffa200',
  restricted: '#d26900',
  suspended: '#ff002b',
  deactivated: '#b4b4b4',
};
