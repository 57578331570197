import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {AccentColors, UserRoleColors} from '../../styles/colors';
import {type User} from '../../types';
import {capitalize} from '../utilities';
import StyledChip from './StyledChip';
import StyledDateFormatting from './StyledDateFormatting';
import StyledTitleLink from './StyledTitleLink';

function StyledUsersTable({users}: {users: User[]}) {
  return (
    <TableContainer component={Paper}>
      <TableHead>
        <TableRow>
          <TableCell>User</TableCell>
          <TableCell>Dates (Created/Updated)</TableCell>
          <TableCell>Role</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => {
          return (
            <TableRow
              key={user.name}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <TableCell sx={{width: '70%'}}>
                <StyledTitleLink
                  name={user.name}
                  id={user.id}
                  linkTo={`/users/${user.id}`}
                  nameFontSize="h3"
                />
                <Box sx={{display: 'flex', alignItems: 'center', mt: '4px'}}>
                  <Typography
                    variant="h4"
                    noWrap
                    sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                  >
                    {user.email}
                  </Typography>
                  {user.is_sso && (
                    <StyledChip size="small" label="SSO" color={AccentColors.AMBER} sx={{ml: 1}} />
                  )}
                </Box>
              </TableCell>
              <TableCell sx={{width: '70%'}}>
                <StyledDateFormatting
                  created_at={user.created_at}
                  updated_at={user?.updated_at}
                  include_title={false}
                />
              </TableCell>
              <TableCell>
                <StyledChip
                  size="small"
                  label={capitalize(user.role)}
                  color={UserRoleColors[user?.role as keyof typeof UserRoleColors]}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
}

export default StyledUsersTable;
