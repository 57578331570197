import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useState} from 'react';
import {patchOrg} from '../../../actions/organizationActions';
import {useDashboardApi} from '../../../hooks/use-api';
import {type Organization} from '../../../types';

interface ChangePodQuotaPopupProps {
  organization: Organization;
  open: boolean;
  handleClose: () => void;
}

const styles = {
  container: {minWidth: 400},
};

function ChangePodQuotaPopup({organization, open, handleClose}: ChangePodQuotaPopupProps) {
  const [quotaOverride, setQuotaOverride] = useState(organization.pod_quota_override || 0);
  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    data: {pod_quota_override: number};
  }>();

  const submitQuotaChange = () => {
    setQueryData({
      action: patchOrg,
      data: {
        organizationId: organization.id,
        data: {
          pod_quota_override: quotaOverride,
        },
      },
    });
    handleClose();
  };

  return (
    <BaseModal open={open} onClose={handleClose} sx={styles.container}>
      <form onSubmit={submitQuotaChange}>
        <ModalHeader
          title="Change Pod Quota"
          subtitle={`Update the pod quota for: ${organization.id}`}
          close={handleClose}
        />
        <Box height={16} />
        <NumberInput value={quotaOverride} onChange={setQuotaOverride} />
        <Box height={16} />
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" color="primary" autoFocus>
          Confirm
        </Button>
      </form>
    </BaseModal>
  );
}

export default ChangePodQuotaPopup;
