import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {AccentColors} from '../../../styles/colors';
import {type User} from '../../../types';
import Auth0Button from '../../Buttons/Auth0Button';
import StyledChip from '../../StyledComponents/StyledChip';
import StyledDateFormatting from '../../StyledComponents/StyledDateFormatting';
import InfoBox from '../../StyledComponents/StyledInfoBox';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function UserCardLarge(user: User) {
  const {auth0ids, email, name, id, is_sso, created_at, updated_at} = user;

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={2} alignItems="center" mb={1}>
        <Grid xs={11}>
          <StyledTitleLink name={name} id={id} nameColor="white" nameFontSize="h2" />
        </Grid>
        <Grid xs={1}>
          <Auth0Button {...user} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid xs={5}>
          <InfoBox label="Auth0 ID">
            <Box display="flex" alignItems="center" overflow="hidden">
              <Typography variant="h6" noWrap>
                {auth0ids[0]}
              </Typography>
              {is_sso && (
                <StyledChip size="small" label="SSO" color={AccentColors.AMBER} sx={{ml: 1}} />
              )}
            </Box>
          </InfoBox>
        </Grid>
        <Grid xs={3}>
          <InfoBox label="Email">
            <Typography variant="h6" noWrap>
              {email}
            </Typography>
          </InfoBox>
        </Grid>
        <Grid xs={3}>
          <StyledDateFormatting created_at={created_at} updated_at={updated_at} />
        </Grid>
      </Grid>
    </Box>
  );
}
export default UserCardLarge;
