import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput, {type OutlinedInputProps} from '@mui/material/OutlinedInput';
import {type Control, Controller, type Path} from 'react-hook-form';
import {type NumberFormatValues, NumericFormat, type NumericFormatProps} from 'react-number-format';
import InputWrapper from './Utils/InputWrapper';
import {inputStyles} from './Utils/styles';
import {type InputProps} from './Utils/types';

type NumericFormattingProps = Omit<
  NumericFormatProps,
  'size' | 'color' | 'inputProps' | 'value' | 'onChange'
> & {max?: number};

interface UnControlledStyledNumberInputProps extends InputProps, NumericFormattingProps {
  value: number;
  onChange: (value: number) => void;
}

interface ControlledStyledNumberInputProps<T extends Record<string, any>>
  extends InputProps,
    NumericFormattingProps {
  name: Path<T>;
  control: Control<T>;
  validate?: (data: T) => boolean;
}

type StyledNumberInputProps<T extends Record<string, any>> =
  | UnControlledStyledNumberInputProps
  | ControlledStyledNumberInputProps<T>;

function NumberInput<T extends Record<string, any>>(props: StyledNumberInputProps<T>) {
  const isValueAllowed = (values: NumberFormatValues) => {
    return !(props.max !== undefined && (values.floatValue || 0) > props.max);
  };
  const numericFormatProps: Omit<OutlinedInputProps, 'size' | 'defaultValue' | 'type' | 'color'> &
    NumericFormattingProps = {
    id: props.id,
    name: props.name,
    size: 'small',
    margin: 'dense',
    color: props.color || 'primary',
    placeholder: props.placeholder,
    required: props.required,
    disabled: props.disabled,
    autoFocus: props.autoFocus,
    startAdornment: props.startAdornment ? (
      <InputAdornment position="start">{props.startAdornment}</InputAdornment>
    ) : undefined,
    endAdornment: props.endAdornment ? (
      <InputAdornment position="end">{props.endAdornment}</InputAdornment>
    ) : undefined,
    fullWidth: props.fullWidth,
    'aria-placeholder': props.placeholder,
    sx: {...inputStyles.input, ...props.sx},
    inputProps: {
      'data-testid': props.id || props.name || props.label?.toLowerCase(),
      'aria-label': props.label?.toLowerCase(),
      readOnly: props.readOnly,
    },
    ...props.outlineInputProps,
    max: props.max,
    isAllowed: isValueAllowed,
    thousandSeparator: true,
    allowNegative: props.allowNegative,
    allowLeadingZeros: props.allowLeadingZeros,
    decimalScale: props.decimalScale || 0,
    customInput: OutlinedInput as NumericFormatProps['customInput'],
  };

  const handleKeyPress = (
    event: React.KeyboardEvent,
    currentValue: number,
    onChange: (value: number) => void,
  ) => {
    let newValue = currentValue;

    switch (event.key) {
      case 'ArrowUp':
        newValue += 1;
        break;
      case 'ArrowDown':
        newValue -= 1;
        break;
      default:
        return;
    }

    // Ensure the value stays within any specified bounds
    if (newValue < 0 && !numericFormatProps?.allowNegative) {
      newValue = 0;
    }
    if (numericFormatProps?.max !== undefined && newValue > numericFormatProps.max) {
      newValue = numericFormatProps.max;
    }
    onChange(newValue);
  };

  if ('control' in props) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={{validate: props.validate}}
        render={({field, fieldState: {error}}) => (
          <InputWrapper {...props} error={props.error || error?.message}>
            <NumericFormat
              value={field.value as any}
              onValueChange={(values, _sourceInfo) => {
                field.onChange(values.floatValue);
              }}
              onKeyDown={(e) => handleKeyPress(e, field.value, field.onChange)}
              error={!!(props.error || error?.message)}
              {...numericFormatProps}
            />
          </InputWrapper>
        )}
      />
    );
  }
  return (
    <InputWrapper {...props}>
      <NumericFormat
        value={props.value as any}
        onValueChange={(values, _sourceInfo) => {
          props.onChange(values.floatValue as number);
        }}
        onKeyDown={(e) => handleKeyPress(e, props.value, props.onChange)}
        error={!!props.error}
        {...numericFormatProps}
      />
    </InputWrapper>
  );
}

export default NumberInput;
