import CloseIcon from '@mui/icons-material/Close';
import {type IconButtonProps} from '@mui/material/IconButton';
import {type ModalProps as MuiModalProps} from '@mui/material/Modal';
import MuiModal from '@mui/material/Modal';
import Paper, {type PaperProps} from '@mui/material/Paper';
import {type Theme} from '@mui/material/styles';
import type * as React from 'react';
import IconButton from '../../Buttons/IconButton';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    p: 4,
    maxHeight: '80%',
    overflowY: 'auto',
    boxShadow: 16,
    position: 'relative',
    outline: 'none',
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    top: 24,
    zIndex: (theme: Theme) => theme.zIndex.modal + 1,
  },
  backdrop: {
    backgroundColor: 'rgba(45, 61, 77, 0.54)',
  },
  hidden: {
    display: 'none',
  },
};

export interface BaseModalProps {
  id?: string;
  open: boolean;
  onClose?: () => void;
  /**
   * @deprecated Use `<ModalHeader />` instead.
   */
  showCloseIcon?: boolean;
  /**
   * @deprecated Use `<ModalHeader />` instead.
   */
  closeIconSx?: IconButtonProps['sx'];
  children: React.ReactNode;
  hideBackdrop?: boolean;
  sx?: Required<PaperProps['sx']>;
  container?: MuiModalProps['container'];
  hidden?: boolean;
}

function BaseModal({
  id,
  open,
  onClose,
  showCloseIcon,
  closeIconSx,
  children,
  hideBackdrop,
  sx,
  container,
  hidden,
}: BaseModalProps) {
  const onClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  return (
    <MuiModal
      container={container}
      id={id}
      data-testid={id}
      open={open}
      onClick={onClick}
      onClose={onClose}
      sx={[styles.modal, hidden ? styles.hidden : {}]}
      slotProps={{
        backdrop: {
          sx: styles.backdrop,
          invisible: hideBackdrop,
        },
      }}
    >
      <Paper sx={{...styles.paper, ...sx}}>
        {showCloseIcon && (
          <IconButton
            id="close-modal-icon"
            ariaLabel="close icon"
            icon={<CloseIcon />}
            onClick={onClose}
            sx={{...styles.closeIcon, ...closeIconSx}}
          />
        )}
        {children}
      </Paper>
    </MuiModal>
  );
}

export default BaseModal;
