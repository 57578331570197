import {keyframes} from '@emotion/react';
import {Box} from '@mui/material';
import PineconeLogoLight from '../icons/pinecone_logo_small_light.svg';

const spin = keyframes`
from {
    transform:rotate(0deg);
}
to {
    transform:rotate(360deg);
}`;

function AnimatedPineconeLogo() {
  return (
    <Box
      sx={{
        animation: `${spin} 1s ease-in-out infinite`,
      }}
    >
      <Box src={PineconeLogoLight} component="img" />
    </Box>
  );
}

export default AnimatedPineconeLogo;
