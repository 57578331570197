import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {QuotaKeys} from '@pinecone-experience/commons/quotas';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useProjectQuotaLimits, useUserRolesByProjectId} from '../../../hooks/selectors';
import {getAccentColor} from '../../../styles/utils';
import {type Project} from '../../../types';
import UserChip from '../../Chips/UserChip';
import MoveProjectPopup from '../../MoveProjectPopup';
import StyledChip from '../../StyledComponents/StyledChip';
import StyledDateFormatting from '../../StyledComponents/StyledDateFormatting';
import InfoBox from '../../StyledComponents/StyledInfoBox';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function ProjectCardLarge(project: Project) {
  const {
    id,
    name,
    organization_id: organizationId,
    created_at,
    updated_at,
    vault_id,
    environment,
    quota,
    index_quota,
  } = project;
  const users = useUserRolesByProjectId(id);
  const quotaLimits = useProjectQuotaLimits(project.id);
  const serverlessIndexesPerProjectLimit = Math.max(
    index_quota,
    quotaLimits?.[QuotaKeys.SERVERLESS_INDEXES_PER_PROJECT]?.value || 0,
  );
  const navigate = useNavigate();

  const [openPopup, setPopupOpen] = useState(false);

  const handleClickPopupOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <StyledTitleLink name={name} id={id} nameColor="white" nameFontSize="h2" />
        <Button variant="contained" color="primary" onClick={handleClickPopupOpen}>
          Move project
        </Button>
        <MoveProjectPopup open={openPopup} project={project} handleClose={handleClose} />
      </Grid>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid xs={4}>
          <InfoBox label="Vault ID" text={vault_id} />
        </Grid>
        <Grid xs={4}>
          <InfoBox label="Pod Quota" text={quota} />
        </Grid>
        <Grid xs={4}>
          <InfoBox label="Serverless Quota" text={serverlessIndexesPerProjectLimit} />
        </Grid>
        <Grid xs={4}>
          <StyledDateFormatting created_at={created_at} updated_at={updated_at} />
        </Grid>
        <Grid xs={4}>
          <InfoBox label="Environment" text={environment} />
        </Grid>
        <Grid xs={4}>
          <InfoBox label="Organization">
            <StyledChip
              label={organizationId}
              color={getAccentColor(organizationId)}
              onClick={() => {
                navigate(`/organizations/${organizationId}`);
              }}
            />
          </InfoBox>
        </Grid>
        <Grid xs={12}>
          <InfoBox label="Users">
            <Box display="flex" flexWrap="wrap">
              {users.map(({userId, role}) => {
                return <UserChip key={userId} userId={userId} role={role} />;
              })}
            </Box>
          </InfoBox>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ProjectCardLarge;
